.my-card {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: calc(100vh - 40px); */
    margin: 0 !important;
    box-shadow: 0px 0px 25px rgba(70, 70, 70, 0.349);
    background: var(--main-color);
}

.card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.time-block {
    display: flex;
    align-items: center;
    justify-content: center;    
}
.time-block__text {
    font-size: 1rem;
    margin: 0;
}
.card-body img{
    max-width: 320px;
    max-height: 200px;
    margin: 0 !important;
}
.privacy-box {
    display: flex;
    align-items: center;
    gap: 20px;
}
.privacy {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
@media (max-width: 450px) {
    .card-body img{
        width: 100%;
    }
}
