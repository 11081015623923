.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.edge-size {
    box-shadow: rgb(0 0 0 / 2%) 0px 1px 3px 0px, rgb(27 31 35 / 15%) 0px 0px 0px 1px;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	/* min-height: 100vh; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}
.container {
	/* min-height: 100vh; */
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0;
	border: 0;
	border-radius: 0;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
