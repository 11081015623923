@font-face {
	font-family: 'PT Sans';
	src: url('../fonts/PTSans-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'PT Sans';
	src: url('../fonts/PTSans-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

:root {
	--main-color: rgba(255, 249, 223, .7);
	--dark-color: rgba(0, 44, 2, .8);
	--black-color: rgba(0, 0, 0, .9);
	--second-color: rgba(255, 243, 187, .9);
	--btn-primary: rgb(4, 131, 0);
	;
	--btn-info: rgba(255, 214, 34, 0.89);
	--btn-secondary: rgba(117, 117, 117, .8);
	--btn-privacy: rgba(196, 195, 255, .7);
	;
	--color-light: rgb(255, 255, 255);
}

body {
	margin: 0;
	font-family: 'PT Sans', 'Verdana', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--dark-color) !important;
	min-height: 100vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	background-image: url('../static/flags-bg.jpg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
}

.input-group-text {
	background-color: var(--second-color);
	color: var(--black-color);
}

.btn-primary {
	background-color: var(--btn-primary);
	border-color: var(--btn-primary);
	color: var(--color-light);
}

.btn-info {
	background-color: var(--btn-info);
	border-color: var(--btn-info);
	color: var(--black-color);
}

.btn-secondary {
	background-color: var(--btn-secondary);
	color: var(--color-light);
}

.btn-privacy {
	background-color: var(--btn-privacy);
	color: var(--black-color);
}